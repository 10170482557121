.buffer-top {
    margin-top: 23px;
}

.align-middle {
    vertical-align: middle;
}

.loading {
    position: relative;

    background-image: url(../../images/gui/loaders/preloader-light-160x20.gif);
    background-position: center 20px;
    background-repeat: no-repeat;
    background-size: 160px 20px;

    min-height: 60px;
}

td.loading { background-position: center center; }

.loading.light {
    background-image: url(../../images/gui/loaders/preloader-dark-160x20.gif);
    background-size: 158px 14px;
}

.loading.loading-small { background-size: 80px 10px; }
.loading.loading-small.light { background-size: 79px 7px; }

.loading-indicator {
    background-image: url(../../images/gui/loaders/preloader-light-160x20.gif);
    width: 160px; margin-left: -80px;
    height: 20px; margin-top: -10px;
    position: absolute; top: 50%; left: 50%;

    display: none;
}

.loading-indicator.center-block {
    position: static;
    margin: 2px auto;
}

.loading .loading-indicator {
    display: block;
}