﻿@import "../../lib/elements.less";

#al-rs-section {
    background-color: #2a2a2a;
    background-color: rgba(0,0,0,0.1);

    &.unlinked-vehicles {
        .data-table-controls {
            display: none;
        }

        .action-row {
            margin-bottom: 20px;
        }
    }

    table tr.linked {
        background-color: #AEECAE;

        td:first-child {
            position: relative;

            &:before {
                @size: 20px;
                content: '\f00c';
                display: block;
                position: absolute;
                top: 50%;
                left: -25px;
                font-family: 'FontAwesome';
                font-size: 17px;
                color: #5CB85C;
                background: #FFFFFF;
                width: @size;
                height: @size;
                line-height: @size;
                padding: 1px 2px;
                border-radius: @size/2;
                margin-top: -@size/2;
                -moz-animation: zoomIn 250ms linear 0 forwards;
                -webkit-animation: zoomIn 250ms linear 0 forwards;
                animation: zoomIn 250ms linear 0 forwards;
            }
        }

        &.selected {
            background-color: #76d6f8;

            &:hover {
                background-color: #38bcef;
            }
        }
    }
}

#al-aces-section {
    background-color: rgba(255,255,255,0.1);
    background-color: #3f3f3f;

    .action-row {
    }

    .cur-filters {
        margin: 5px 0;

        .label {
            margin: 0 2.5px;
        }
    }

    table tr.linked {
        td:last-child {
            position: relative;

            &:before {
                @size: 20px;
                content: '\f0c1';
                display: block;
                position: absolute;
                top: 50%;
                right: -25px;
                font-family: 'FontAwesome';
                font-size: 17px;
                color: #fff;

                height: @size;
                line-height: @size;
                margin-top: -@size/2;

                -moz-animation: zoomIn 250ms linear 0 forwards;
                -webkit-animation: zoomIn 250ms linear 0 forwards;
                animation: zoomIn 250ms linear 0 forwards;
            }
        }
    }
}

#al-rs-preview-table {
    position: fixed;
    bottom: 10px;
    margin: 0;
    z-index: 100;
    left: 20%;
    width: 60%;

    display: none;
    box-shadow: 0 0 10px #577FA1;

    tr {
        cursor: default;

        -moz-transition: opacity 0.5s;
        -o-transition: opacity 0.5s;
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;

        &:hover {
            background-color: white;
            .opacity(0.7);
        }
    }
}

.al-section {
    padding: 10px;

    .action-row {
        line-height: 33px;
    }

    .loading-indicator {
        position: absolute;
        top: 160px;
        margin-left: -80px;
    }

    &.ctrlDown {
        .ctrlChar {
            text-decoration: underline;
        }
    }

    table {
        td {
            font-size: 12px;
        }

        tr {
            -moz-transition: background-color 250ms;
            -o-transition: background-color 250ms;
            -webkit-transition: background-color 250ms;
            transition: background-color 250ms;
        }
    }

    .more-results {
        display: none;
        text-align: center;
        padding: 5px 0;
        margin-top: 7px;

        button {
            margin-left: 10px;
        }
    }

    &.unlinked-vehicles {
        .more-results {
            display: block;
        }
    }
}

#al-link-count {
    font-size: 1em;
    margin-right: 10px;
    vertical-align: middle;
}

#wl-vehicles-container {
    .data-table-loading {
        margin-left: -45px;
    }
}
#wl-rs-vehicles-container {
    .data-table-loading {
        margin-left: -15px;
    }
}

@-moz-keyframes zoomIn{
    from { -moz-transform: scale(1.5, 1.5); opacity: 0.4; }
    to { -moz-transform: scale(1, 1); opacity: 1; }
}
@-webkit-keyframes zoomIn{
    from { -webkit-transform: scale(1.5, 1.5); opacity: 0.4; }
    to { -webkit-transform: scale(1, 1); opacity: 1; }
}
@keyframes zoomIn{
    from { transform: scale(1.5, 1.5); opacity: 0.4; }
    to { transform: scale(1, 1); opacity: 1; }
}