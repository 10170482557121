﻿@import (reference) "../lib/elements.less";
@import (reference) "../lib/font-awesome-variables.less";

.archived-data-object {
	tr& {
		color: #aaa;
    font-style: italic;
    
    background-image: linear-gradient(to bottom, #eee, #eee 50%, transparent 50%, transparent);
    background-size: 100% 5px;
	}
}

.clear-both {
	clear:both;
}

hr.secondary {
	border-top-style: dashed;
}

.no-padding { padding: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }

.no-margin {
	margin: 0 !important;
}

.no-underline {
  text-decoration: none !important;
}

.scrollable {
	overflow: auto;
}

.overflow-hidden {
  overflow: hidden !important;
} 

.transparent {opacity: 0;}
.translucent-25 {opacity: 0.25;}
.translucent-50,.translucent {opacity: 0.5;}
.translucent-75 {opacity: 0.75;}

.hover-opaque::hover {opacity: 1;}

.transition-opacity { 
  -moz-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.no-border { border: none !important; }
.no-border-bottom { border-bottom: none !important; }
.no-border-top { border-top: none !important; }
.no-border-left { border-left: none !important; }
.no-border-right { border-right: none !important; }

.primary-link, a.primary-link {
  color: white;
  color: inherit;

  &:hover, &:focus {
    color: white;
    color: inherit;

    outline: none !important;
    text-decoration: underline;
  }
}


.hover-highlight-row .hover-highlight-row-element { opacity: 0.5; }
.hover-highlight-row:hover .hover-highlight-row-element { opacity: 1; }

.rs-container, .rs-form {
	position: relative;

	.loader {
		@margin: 10px;

		position: absolute;
		visibility: hidden;
		top: -@margin;
		left: -@margin;
		right: -@margin;
		bottom: -@margin;

		background: #2D2D2D url(../../../images/gui/loaders/preloader-light-160x20.gif) no-repeat center center;
		border-radius: 5px;
		.opacity(0);

		-moz-transition: visibility 200ms, opacity 200ms;
		-o-transition: visibility 200ms, opacity 200ms;
		-webkit-transition: visibility 200ms, opacity 200ms;
		transition: visibility 200ms, opacity 200ms;

		z-index: 10;
	}

	&.loading {
		background: none;

		.loader {
			visibility: visible;
			.opacity(0.8);
		}
	}
}

abbr.sidewall {
  border-radius: 1em;
  padding: 0.1em 0.6em;
  margin: 0 5px;
  text-decoration: none;

  // Default & Unknown sidewall styling
  background-color: #aaa;
  color: #444; 

  &.BSL { // Black Serrated Letters
    // Striped background with black text with light gray shadow
    background:
      linear-gradient(63deg, #999 23%, transparent 23%) 7px 0, 
      linear-gradient(63deg, transparent 74%, #999 78%), 
      linear-gradient(63deg, transparent 34%, #999 38%, #999 58%, transparent 62%), 
      #444;
    background-size: 8px 24px;

    color: black;
    font-weight: bold;
    text-shadow: #555 1px 1px;
  }

  &.VSB { // Black Serrated Letters
    // Vertical striped background with dark gray text with light gray shadow
    background:
      linear-gradient(90deg, #222 23%, transparent 23%) 7px 0, 
      linear-gradient(90deg, transparent 74%, #222 78%), 
      linear-gradient(90deg, transparent 34%, #222 38%, #222 58%, transparent 62%), 
      #444;
    background-size: 20px 20px;

    color: black;
    font-weight: bold;
    text-shadow: #555 1px 1px;
  }

  &.WS { // White stripe
    // Black background with white vertical stripe with dark gray text with light gray shadow
    background:
      linear-gradient(90deg, transparent 14%, #eee 18%, #eee 23%, transparent 27%), 
      black;
    background-size: 100% 100%;

    padding-left: 5px;

    color: #666;
    font-weight: bold;
    text-shadow: #555 1px 1px;
  }

  &.BLT { // Black Letter
    // Dark gray with gray letters
    background-color: #111;
    color: #aaa;
  }

  &.RBL { // Raised Black Letters
    // Dark gray with gray letters with light gray shadow
    background-color: #111;
    color: #aaa;
    text-shadow: #777 1px 1px;
  }

  &.ORWL { // Outlined Raised White Letters
    // Black with white letters with a gray shadow and a white border
    background-color: black;
    color: white;
    text-shadow: #9a9a9a 2px 2px 1px;
    border: 3px white solid;
  }

  &.BSW { // Black Sidewall
    // Black with light gray letters
    background-color: black;
    color: #aaa;
  }
  
  &.RWL { // Raised White Letters
    // Black with white letters with a gray shadow
    background-color: black;
    color: white;
    text-shadow: #9a9a9a 2px 2px 1px;
  }

  &.WSW { // White Sidewall
    // White with black letters
    background-color: white;
    color: black;
  }

  &.OWL { // Outlined White Letters
    // Black with white letters and a white border
    background-color: black;
    color: white;
    border: 3px white solid;
  }
}

.clickable {
	cursor: pointer;
}

.waiting {
	cursor: wait;
}

input[type="radio"], input[type="checkbox"] {
	margin: 0;
	vertical-align: middle;
}

.collapse-indicator:after {
	content: @fa-var-minus;

	font-style: normal;
	font-family: FontAwesome;
	margin-right: 10px;
	font-size: 0.7em;

	.collapsed & {
		content: @fa-var-plus;
	}

}

.navbar-brand[data-environment] {position: relative;}
.navbar-brand[data-environment]:after {
  content: attr(data-environment);
  position: absolute;
  bottom: -9px;
  left: 137px;
  font-size: 0.6em;
}

.background-alternative-dark {
	background-color: #2a2a2a;
	background-color: rgba(0,0,0,.1);
}
.background-alternative-light {
	background-color: rgba(255,255,255,.1);
	background-color: #3f3f3f
}

.shake {
	-moz-animation: shake-base 500ms infinite ease-in-out;
	-o-animation: shake-base 500ms infinite ease-in-out;
	-webkit-animation: shake-base 500ms infinite ease-in-out;
	animation: shake-base 500ms infinite ease-in-out;
}
.shake-once {
	.shake();
	-moz-animation-iteration-count: 1;
	-o-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.line-through {
	text-decoration: line-through;
}

.inline-statistic {
	display: inline-block;
	margin-right: 20px;

	strong {
		color: #ddd;
		text-transform: uppercase;
		font-size: 0.7em;
		display: block;
	}

	& > span {
		margin-right: 10px;
	}
}

.fraction {
	display: inline-block;
	vertical-align: middle;

	span {
		display: block;
		line-height: 1em;
		border-bottom: 1px solid;
		
		+ span {
			border-bottom: none;
		}
	}
}

canvas {
	vertical-align: middle;
}

.doughnut-legend {
	list-style-type: none;
	padding-left: 20px;
	vertical-align: middle;
	display: inline-block;
	text-align: left;

	span {
		display: inline-block;
		width: 1em;
		height: 1em;
		border-radius: 1em;
		margin-right: 0.5em;
		margin-top: 0.2em;
	}
}

.full-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  background-color: rgba(0,0,0,0.7);
  z-index: 2000;

  -webkit-animation: fade-in 500ms;
  -o-animation: fade-in 500ms;
  animation: fade-in 500ms;
}

.full-loading-icon {
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -43px;
}

.full-loading-text {
  position: absolute;
  top: 50%;
  margin-top: 50px;
  font-size: 24px;
  text-align: center;
  left: 0;
  right: 0;
  line-height: 0.8em;
}

@-webkit-keyframes fade-in { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fade-in { from { opacity:0; } to { opacity:1; } }
@keyframes fade-in { from { opacity:0; } to { opacity:1; } }


@-moz-keyframes shake-base { 0% { transform: translate(0px, 0px) rotate(0deg); }
  2% { transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
  4% { transform: translate(-2.5px, -1.5px) rotate(0.5deg); }
  6% { transform: translate(1.5px, 1.5px) rotate(-1.5deg); }
  8% { transform: translate(-1.5px, -2.5px) rotate(0.5deg); }
  10% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  12% { transform: translate(-1.5px, 1.5px) rotate(-1.5deg); }
  14% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  16% { transform: translate(0.5px, 0.5px) rotate(-0.5deg); }
  18% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  20% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  22% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  24% { transform: translate(-0.5px, -1.5px) rotate(-0.5deg); }
  26% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  28% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  30% { transform: translate(-2.5px, 0.5px) rotate(0.5deg); }
  32% { transform: translate(1.5px, -0.5px) rotate(-0.5deg); }
  34% { transform: translate(0.5px, -2.5px) rotate(-1.5deg); }
  36% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  38% { transform: translate(-2.5px, -0.5px) rotate(0.5deg); }
  40% { transform: translate(-0.5px, 1.5px) rotate(0.5deg); }
  42% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  44% { transform: translate(-2.5px, -2.5px) rotate(0.5deg); }
  46% { transform: translate(0.5px, -2.5px) rotate(0.5deg); }
  48% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  50% { transform: translate(-0.5px, 0.5px) rotate(0.5deg); }
  52% { transform: translate(1.5px, 0.5px) rotate(0.5deg); }
  54% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  56% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  58% { transform: translate(-2.5px, -2.5px) rotate(-0.5deg); }
  60% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  62% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  64% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  66% { transform: translate(-2.5px, 0.5px) rotate(-0.5deg); }
  68% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  70% { transform: translate(0.5px, 1.5px) rotate(0.5deg); }
  72% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  74% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  76% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); }
  78% { transform: translate(-0.5px, -2.5px) rotate(0.5deg); }
  80% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  82% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  84% { transform: translate(-1.5px, -1.5px) rotate(-0.5deg); }
  86% { transform: translate(0.5px, -0.5px) rotate(-1.5deg); }
  88% { transform: translate(-2.5px, -2.5px) rotate(-1.5deg); }
  90% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  92% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  94% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  96% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  98% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); } }
@-webkit-keyframes shake-base { 0% { transform: translate(0px, 0px) rotate(0deg); }
  2% { transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
  4% { transform: translate(-2.5px, -1.5px) rotate(0.5deg); }
  6% { transform: translate(1.5px, 1.5px) rotate(-1.5deg); }
  8% { transform: translate(-1.5px, -2.5px) rotate(0.5deg); }
  10% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  12% { transform: translate(-1.5px, 1.5px) rotate(-1.5deg); }
  14% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  16% { transform: translate(0.5px, 0.5px) rotate(-0.5deg); }
  18% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  20% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  22% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  24% { transform: translate(-0.5px, -1.5px) rotate(-0.5deg); }
  26% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  28% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  30% { transform: translate(-2.5px, 0.5px) rotate(0.5deg); }
  32% { transform: translate(1.5px, -0.5px) rotate(-0.5deg); }
  34% { transform: translate(0.5px, -2.5px) rotate(-1.5deg); }
  36% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  38% { transform: translate(-2.5px, -0.5px) rotate(0.5deg); }
  40% { transform: translate(-0.5px, 1.5px) rotate(0.5deg); }
  42% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  44% { transform: translate(-2.5px, -2.5px) rotate(0.5deg); }
  46% { transform: translate(0.5px, -2.5px) rotate(0.5deg); }
  48% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  50% { transform: translate(-0.5px, 0.5px) rotate(0.5deg); }
  52% { transform: translate(1.5px, 0.5px) rotate(0.5deg); }
  54% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  56% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  58% { transform: translate(-2.5px, -2.5px) rotate(-0.5deg); }
  60% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  62% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  64% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  66% { transform: translate(-2.5px, 0.5px) rotate(-0.5deg); }
  68% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  70% { transform: translate(0.5px, 1.5px) rotate(0.5deg); }
  72% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  74% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  76% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); }
  78% { transform: translate(-0.5px, -2.5px) rotate(0.5deg); }
  80% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  82% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  84% { transform: translate(-1.5px, -1.5px) rotate(-0.5deg); }
  86% { transform: translate(0.5px, -0.5px) rotate(-1.5deg); }
  88% { transform: translate(-2.5px, -2.5px) rotate(-1.5deg); }
  90% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  92% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  94% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  96% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  98% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); } }
@keyframes shake-base { 0% { transform: translate(0px, 0px) rotate(0deg); }
  2% { transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
  4% { transform: translate(-2.5px, -1.5px) rotate(0.5deg); }
  6% { transform: translate(1.5px, 1.5px) rotate(-1.5deg); }
  8% { transform: translate(-1.5px, -2.5px) rotate(0.5deg); }
  10% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  12% { transform: translate(-1.5px, 1.5px) rotate(-1.5deg); }
  14% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  16% { transform: translate(0.5px, 0.5px) rotate(-0.5deg); }
  18% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  20% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  22% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  24% { transform: translate(-0.5px, -1.5px) rotate(-0.5deg); }
  26% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  28% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  30% { transform: translate(-2.5px, 0.5px) rotate(0.5deg); }
  32% { transform: translate(1.5px, -0.5px) rotate(-0.5deg); }
  34% { transform: translate(0.5px, -2.5px) rotate(-1.5deg); }
  36% { transform: translate(0.5px, -2.5px) rotate(-0.5deg); }
  38% { transform: translate(-2.5px, -0.5px) rotate(0.5deg); }
  40% { transform: translate(-0.5px, 1.5px) rotate(0.5deg); }
  42% { transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
  44% { transform: translate(-2.5px, -2.5px) rotate(0.5deg); }
  46% { transform: translate(0.5px, -2.5px) rotate(0.5deg); }
  48% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  50% { transform: translate(-0.5px, 0.5px) rotate(0.5deg); }
  52% { transform: translate(1.5px, 0.5px) rotate(0.5deg); }
  54% { transform: translate(-2.5px, 0.5px) rotate(-1.5deg); }
  56% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  58% { transform: translate(-2.5px, -2.5px) rotate(-0.5deg); }
  60% { transform: translate(-0.5px, -2.5px) rotate(-0.5deg); }
  62% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  64% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  66% { transform: translate(-2.5px, 0.5px) rotate(-0.5deg); }
  68% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  70% { transform: translate(0.5px, 1.5px) rotate(0.5deg); }
  72% { transform: translate(-2.5px, 1.5px) rotate(0.5deg); }
  74% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  76% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); }
  78% { transform: translate(-0.5px, -2.5px) rotate(0.5deg); }
  80% { transform: translate(1.5px, -0.5px) rotate(0.5deg); }
  82% { transform: translate(-1.5px, 0.5px) rotate(-0.5deg); }
  84% { transform: translate(-1.5px, -1.5px) rotate(-0.5deg); }
  86% { transform: translate(0.5px, -0.5px) rotate(-1.5deg); }
  88% { transform: translate(-2.5px, -2.5px) rotate(-1.5deg); }
  90% { transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
  92% { transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
  94% { transform: translate(-1.5px, -0.5px) rotate(0.5deg); }
  96% { transform: translate(1.5px, 0.5px) rotate(-0.5deg); }
  98% { transform: translate(-0.5px, -1.5px) rotate(0.5deg); } }