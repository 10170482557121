.note-list {
    list-style-type: none;
    padding: 0;
}

.note-list-item {
    padding: 5px 10px;
    margin-bottom: 10px; 
}

.note-list-item-archived {
    .note-list-item-author {
        text-decoration: line-through;
        color: #999;
    }
    .note-list-item-text {
        color: #ccc;
    }
}

.note-list-item-header {
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: #222;
}

.note-list-item-time {
    color: #999;
    float: right;
}

.note-list-item-text {
    padding: 0 10px;
    white-space: pre-wrap;
}

.note-list-no-notes-message {
    text-align: center;
    font-size: 1.4em;
    margin-top: 36px;
}

.note-list-item-menu {
    float: right;
    
    > button {
        background: none;
        border: none;
        margin-left: 10px;
        font-size: 20px;
        margin-top: -5px;
        padding: 0;
        width: 16px;

        &:disabled:after {
            content: url(../../../images/gui/loaders/ajax-loader-small-blackonwhite.gif);
        }

        &:after {
            content: '\f142';
            font-family: FontAwesome;
        }  
    }
}

.note-list-item-edit-actions {
    text-align: right;
    margin-top: 10px;

    > button {
        margin-left: 10px;  
    }
}