﻿@import (reference) "../lib/elements.less";
@import (reference) "../lib/font-awesome-variables.less";
@import (reference) "../lib/bootstrap-variables.less";

body {
    .dropdown.requires-roles {
        // So dropdowns that require roles don't flash in
        // before the users roles are retrieved.
        display: none;
    }

    .load-overlay {
        background: #333 url(/images/gui/loaders/preloader-light-160x20.gif) center center no-repeat;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
        position: absolute;
    }

    .view-area {
        min-height: 400px;
        position: relative;
    }

    .banner-notification {
        background: #efefef;
        color: #000;
        display: block;
        font-size: 20px;
        margin: -10px 0 20px 0;
        padding: 5px;
        text-align: center;
        text-decoration: none;

        position: fixed;
        top: 90px;
        z-index: 100;
        left: 0;
        right: 0;

        .fa {
            &.pull-right {
                line-height: 30px;
            }
        }
    }

    h2 {
        span {
            border: 3px solid #1cf9e3;
            border-radius: 21px;
            color: #1cf9e3;
            display: -moz-inline-stack;
            display: inline-block;
            *display: inline;
            height: 42px;
            margin-right: 15px;
            text-align: center;
            vertical-align: middle;
            width: 42px;
            zoom: 1;
        }

        span + a {
            vertical-align: middle;
            color: white;

            &:hover {
                color: white;
            }

            &:before {
                content: "\f068";
                font-family: FontAwesome;
                font-size: 0.8em;
                vertical-align: middle;
                float: right;
                margin-right: 10px;
            }

            &.collapsed:before {
                content: "\f067";
            }

            &.complete:after {
                content: "\f00c";
                font-family: FontAwesome;
                font-size: 0.8em;
                vertical-align: middle;
            }
        }
    }
}

.container {
    width: 100% !important;
    padding-left: 40px;
    padding-right: 40px;
}

.background-light {
    background-color: #e3e3e3;
}

article.footer {
    position: relative;
}

.hidden-file {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

::-webkit-file-upload-button {
    cursor: pointer !important;
    vertical-align: top;
}

#data-shard-switcher {
    small {
        display: inline-block;
        vertical-align: middle;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.circle-icon-button {
    @size: 26px;
    width: @size;
    height: @size;
    border-radius: @size/2;
    position: relative;
    vertical-align: middle;
    border-style: none;
    outline: none;
    background-color: #444;
    background-color: rgba(0,0,0,.4);

    margin-left: 5px;

    &:after {
        position: absolute;
        font-family: "FontAwesome";
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: @size;
        font-size: @size - 6;
    }
}

.circle-icon-button(@icon, @bgColor: #e0e0e0, @textColor: #333) {
    background-color: @bgColor;

    &:after {
        content: @icon;
        color: @textColor;
    }

    &:hover {
        background-color: saturate(lighten(@bgColor, 10%), 10%);
    }

    &[disabled], &.disabled {
        background-color: desaturate(@bgColor, 30%);
        cursor: default;

        &:after {
            color: lighten(@textColor, 20%);
        }
    }
}

.circle-icon-button-enabled {
    .circle-icon-button(@fa-var-check, @brand-success, #fff);
}

.circle-icon-button-disabled {
    .circle-icon-button(@fa-var-remove);
}

.circle-icon-button-locked {
    .circle-icon-button(@fa-var-lock, #428BCA, #fff);
}

.circle-icon-button-unlocked {
    .circle-icon-button(@fa-var-unlock);
}

.circle-icon-button-filter {
    .circle-icon-button(@fa-var-filter);
}

.circle-icon-button-help {
    .circle-icon-button(@fa-var-question, #428BCA, #fff);
}

.circle-icon-button-settings {
    .circle-icon-button(@fa-var-gear);
}

.thumbnail-grid {
    text-align: center;
    white-space: nowrap;

    span {
        text-align: center;
        display: inline-block;
        width: 58px;
        font-size: 12px;
        white-space: normal;
        vertical-align: middle;

        &.text-muted {
            line-height: 74px;
            text-decoration: line-through;
        }

        &.label {
            padding: .2em 0;

            &.text-muted {
                line-height: 1em;
                background-color: #ddd;
            }
        }
    }

    img {
        width: 50px;
        height: 50px;
    }
}

.btn-block .dropdown-menu {
    width: 100%;
}

.rs-dropdown {
    .btn {
        span {
            overflow: hidden;
            display: inline-block;
            vertical-align: top;
            text-overflow: ellipsis;
        }
    }

    .btn-default {
        color: #333;
    }

    > .btn:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
    }
}

.breakAll {
    -ms-word-break: break-all;
    word-break: break-all;
}

.breakWord {
    .breakAll();
    word-break: break-word;
}

@media (max-width: @screen-sm-max) {
    .navbar {
        text-align: center;
    }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .navbar {
        .nav {
            margin: 0;
        }

        .btn {
            padding: 6px 7px;
        }
    }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
}

.minimize {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    color: #efefef;
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    filter: alpha(opacity=20);
    opacity: .2;
    text-shadow: 0 1px 0 #333;
    margin-right: 8px;
    margin-top: -8px;

    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        filter: alpha(opacity=50);
        opacity: .5;
    }
}

.minimized-modal-container {
    position: fixed;
    bottom: 0;
    right: 100px;
}

.minimized-modal-button {
    margin: 0 5px;
    background-color: #555;
    border: none; outline: none;
    box-shadow: 0 -1px 1px #222;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
    padding-right: 30px;
    position: relative;

    &:after {
        content: @fa-var-level-up;
        position: absolute; 
        right: 8px;
        margin-left: 7px;
        margin-right: 2px;
        font-family: FontAwesome;
        font-size: 1.5em;
        line-height: 1em;
        vertical-align: bottom;
    }
}