﻿@import "../../lib/elements.less";

.image-uploader {
    position: relative;
    padding: 5px;
    background: #444;        
    border: 1px dashed;
    .rounded(5px);

    .no-image-text {
        display: none;
    }

    &.no-image {
        min-height: 40px;

        img {
            display: none;
        }

        .no-image-text {
            display: block;
            text-align: center;

            position: absolute;
            top: 50%; margin-top: -10px;
            left: 0; right: 0;
        }
    }

    .upload-overlay {
        background-color: rgba(0,0,0,0.75);
        color: white;
        padding: 3px; 
        text-align: center;
        position: absolute; top: 0; left: 0; right: 0; bottom: 0;

        .opacity(0);
        .transition();

        i {
            font-size: 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -13px;
            margin-top: -14px;

            pointer-events: none;
        }
    }

    &:hover, &.file-over {
        .upload-overlay{
            .opacity(1);
        }
    }

    &.loading {
        background: url(../../../images/gui/loaders/ajax-loader-small-blackonwhite.gif) no-repeat center;
        min-height: 20px;

        img {
            display: none;
        }

        .upload-overlay {
            display: none;
        }
    }

    input {
        display: block;
        width: 100%; height: 100%;
        opacity: 0;
        cursor: pointer;

        -webkit-appearance: none;

        ::-webkit-file-upload-button {
            display: block;
            cursor: pointer;
            -webkit-appearance: none;
            width: 100%; height: 100%;
        }
    }
}