﻿table.data-table {
    margin: 0 auto;
    clear: both;
    color: #1a1a1a;
    width: 100%;
    margin-bottom: 35px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-collapse: collapse;

    thead {
        &.floating {
            -moz-box-shadow: 0 6px 4px -4px rgba(0,0,0,0.3);
            -webkit-box-shadow: 0 6px 4px -4px rgba(0,0,0,0.3);
            box-shadow: 0 6px 4px -4px rgba(0,0,0,0.3);
            -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=270, Color='#b3b3b3')";
            filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=270, Color='#b3b3b3');
        }

        th {
            padding: 5px 20px 5px 5px;
            border-top: 1px solid #dee3ea;
            font-weight: 600;
            cursor: pointer;
            *cursor: hand;
            background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTRDMDM5NjkyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTRDMDM5NkEyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxNEMwMzk2NzIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxNEMwMzk2ODIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pm8NGvcAAADkSURBVHjaYvz//z8DtQATAxUBCzbBu3fvInO5gLgNiMuA+BdMUFlZmSyXZQNxFhCnUupNLSDOA2JWIC4AOYhcwxiBuBiIZaB8FajBjOQY5gDEgWhiiUBsTaphvEBcC8SCWMRrgJidFMNCoC74gQU7AnEQ1nChZqLFlc4igdQCIP6HwzcZwHQ2n1hvrgPi/UDMgQUfBeI1pITZTyBuAeLPaOLvgbgZizjBpAFyAbpX1gPxAXLSGShmJgHxHSj/CRD3QsXJyk6gHD8BiH9DDb5GcmyigdlArArEUwkpZBy0hSNAgAEA5Ho0sMdEmU8AAAAASUVORK5CYII=) no-repeat center right;
            background-color: #fff;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.disabled {
                background: #fff;
                cursor: default;
                padding: 5px;
            }

            &.asc {
                background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxNjIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxNTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+z5ABTAAAAI5JREFUeNpi/P//PwO1ABMDFQELIQXVjfe4gFQbEJe11iv9otRl2UCcBcSphBQy4gszoKu0gNROIJYB4jtA7AF03V2SXQY0iBFIFUMNAgEVIM6DipPsTQcgDkQTSwRia5IMA9rOC6RqgVgQTQokXgOUZyfFZSFQF/zAgh2BOIjkCBjQRDtq2Khh9DAMIMAAT9AmNBDSXegAAAAASUVORK5CYII=) no-repeat center right;

                &.disabled {
                    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODJBREM2MTEyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODJBREM2MTIyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0M0ZDRUZGRTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxMDIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pr4T1OwAAACOSURBVHjaYvz//z8DtQATAxUBCyEF1Y33uIBUGxCXtdYr/aLUZdlAnAXEqYQUMuILM6CrtIDUTiCWAeI7QOwBdN1dkl0GNIgRSBVDDQIBFSDOg4qT7E0HIA5EE0sEYmuSDAPazgukaoFYEE0KJF4DlGcnxWUhUBf8wIIdgTiI5AgY0EQ7atioYfQwDCDAAE/QJjQQ0l3oAAAAAElFTkSuQmCC) no-repeat center right;
                }
            }

            &.desc {
                background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExQjM4MkY2QzVGRUYwRTJDNCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MkFEQzYxQTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MkFEQzYxOTIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTFCMzgyRjZDNUZFRjBFMkM0Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+1fsfwAAAAJdJREFUeNpi/P//PwO1ABMDFcGoYaOG0cMwFmyC1Y33IoHUAiD+h8MBGa31SvOJddk6IN4PxBxY8FEgXkO0N4G2/gRSLUD8GU3qPRA3A+U/kxpmIBege2U9EB/ApYERX6kBDDtlILUDiFWA+AkQuwNddY2s2ARqvAukJgDxbyCehM8gnLGJBmYDsSoQTyWkkHHQFo4AAQYAAA0piq4hbqwAAAAASUVORK5CYII=) no-repeat center right;

                &.disabled {
                    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDNGQ0VGRjQyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDNGQ0VGRjUyMkMxMTFFMUExRjFBREFENUIyQTUzOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxNEMwMzk2QjIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxNEMwMzk2QzIyQzExMUUxQTFGMUFEQUQ1QjJBNTM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pnt2WfgAAACJSURBVHjaYvz//z8DtQATAxXBqGGjhtHDMBZsgnfv3o0EUguA+B8OB2QoKyvPJ9Zl64B4PxBzYMFHgXgNKd78CcQtQPwZTfw9EDdjEScYZiAXoHtlPRAfICcCQMXJJCC+A+U/AeJeqDhZsXkXiCcA8W+owddIjk00MBuIVYF4KiGFjIO2cAQIMAAzGSDTlIC38gAAAABJRU5ErkJggg==) no-repeat center right;
                }
            }
        }
    }

    tbody {
        font-size: 0.9em;
        
        tr {
            &.selected {
                zoom: 1;

                &.even {
                    background-color: #C2F2FF;
                }

                &.odd {
                    background-color: #D8F2FF;
                }

                &:hover {
                    background-color: #CEE8F2;
                }
            }

            &:hover {
                background-color: #CEE8F2;
            }

            &.linked-green {
                background-color: #AEECAE;

                td:first-child {
                    position: relative;

                    &:before {
                        content: '\f00c';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: -25px;
                        font-family: 'FontAwesome';
                        font-size: 17px;
                        color: #5CB85C;
                        background: #FFFFFF;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        padding: 1px 2px;
                        border-radius: 10px;
                        margin-top: -10px;
                        -moz-animation: zoomIn 250ms linear 0 forwards;
                        -webkit-animation: zoomIn 250ms linear 0 forwards;
                        animation: zoomIn 250ms linear 0 forwards;
                    }
                }
            }

            &.linked-green-right {
                td:first-child {
                    &:before {
                        content: normal;
                        display: none;
                    }
                }

                td:last-child {
                    position: relative;

                    &:before {
                        content: '\f0c1';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -25px;
                        font-family: 'FontAwesome';
                        font-size: 17px;
                        color: #fff;
                        height: 20px;
                        line-height: 20px;
                        margin-top: -10px;
                        -moz-animation: zoomIn 250ms linear 0 forwards;
                        -webkit-animation: zoomIn 250ms linear 0 forwards;
                        animation: zoomIn 250ms linear 0 forwards;
                    }
                }
            }

            &.highlight {
                background-color: #f8c072;
            }

            &.status-loading {
                td:first-child {
                    position: relative;

                    &:before {
                        content: '\f110';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: -25px;
                        font-family: 'FontAwesome';
                        font-size: 17px;
                        color: #fff;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        padding: 1px 2px;
                        margin-top: -10px;
                        -webkit-animation: fa-spin 2s infinite linear;
                        animation: fa-spin 2s infinite linear;
                    }
                }
            }

            &.status-loading-right {
                td:first-child {
                    &:before {
                        content: normal;
                        display: none;
                    }
                }

                td:last-child {
                    position: relative;

                    &:before {
                        content: '\f110';
                        display: block;
                        position: absolute;
                        top: 50%;
                        right: -25px;
                        font-family: 'FontAwesome';
                        font-size: 17px;
                        color: #fff;
                        height: 20px;
                        line-height: 20px;
                        margin-top: -10px;
                        -webkit-animation: fa-spin 2s infinite linear;
                        animation: fa-spin 2s infinite linear;
                    }
                }
            }
        }

        &.rows-clickable tr {
            cursor: pointer;
        }
    }

    tfoot {
        th {
            background-color: #fff !important;
            border-top: 1px solid #dee3ea;
            font-weight: 600;
            padding: 15px 18px 10px 18px;
        }
    }

    th, td {
        padding: 0;
        margin: 0;

        &.no-padding {
            padding: 0;
        }
    }

    td {
        padding: 5px 5px;
        border-bottom: 1px solid #edf2f7;

        &.center {
            text-align: center;
        }

        &.no-results {
            text-align: center;
            background-color: #eee;
            padding: 40px 0;
        }

        &.status {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 11px;
                    right: 2px;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                }

                &.status-primary:after {
                    background-color: #428bca;
                }

                &.status-info:after {
                    background-color: #5bc0de;
                }

                &.status-success:after {
                    background-color: #5cb85c;
                }

                &.status-warning:after {
                    background-color: #f0ad4e;
                }

                &.status-danger:after {
                    background-color: #d9534f;
                }
            }
    }

    tr {
        &, &.even {
            background-color: #f9f9f9;

            td.sort {
                background-color: #f3f3f3;
            }
        }

        &.odd {
            background-color: #fff;

            td.sort {
                background-color: #fcfcfc;
            }
        }

        &.disabled {
            background: #d7d7d7;
            color: #777;
            cursor: not-allowed;
        }
    }

    .select-cell {
        width: 50px;
        text-align: center;
    }

    .img {
        height: 31px;
        float: left;
        background: white;
        width: 31px;
        border: 1px solid #dfe4eb;
        text-align: center;
        cursor: pointer;
        margin-right: 23px;

        img {
            margin-top: 4px;
        }
    }

    .label {
        margin: 0 2.5px;
    }
    .label-block {
        margin: 2.5px 0;
    }

    .btn {
        font-size: 1em;

        &:disabled {
            opacity: 0.25;
            filter: alpha(opacity=25);
        }
    }

    .product-images {
        white-space: nowrap;
    }

    &.data-table-singleselect {
        tbody {
            tr {
                cursor: pointer;

                &.selected {
                    &.odd, &.even {
                        background-color: #76d6f8;
                        zoom: 1;
                    }

                    &:hover {
                        background-color: #38bcef;
                    }
                }
            }
        }
    }

    &.data-table-compact {
        thead {
            th {
                padding: 5px 2px;
                font-size: 13px;
                background: #fff;

                &.asc {
                    background: #70d3f4;
                    background: -moz-linear-gradient(top, #70d3f4 0%, #ace0ef 23%, #f7f7f7 61%, #f7f7f7 100%);
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#70d3f4), color-stop(23%,#ace0ef), color-stop(61%,#f7f7f7), color-stop(100%,#f7f7f7));
                    background: -webkit-linear-gradient(top, #70d3f4 0%,#ace0ef 23%,#f7f7f7 61%,#f7f7f7 100%);
                    background: -o-linear-gradient(top, #70d3f4 0%,#ace0ef 23%,#f7f7f7 61%,#f7f7f7 100%);
                    background: -ms-linear-gradient(top, #70d3f4 0%,#ace0ef 23%,#f7f7f7 61%,#f7f7f7 100%);
                    background: linear-gradient(to bottom, #70d3f4 0%,#ace0ef 23%,#f7f7f7 61%,#f7f7f7 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#70d3f4', endColorstr='#f7f7f7',GradientType=0 );
                }

                &.desc {
                    background: #f7f7f7;
                    background: -moz-linear-gradient(top, #f7f7f7 0%, #f7f7f7 39%, #ace0ef 77%, #70d3f4 100%);
                    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f7f7f7), color-stop(39%,#f7f7f7), color-stop(77%,#ace0ef), color-stop(100%,#70d3f4));
                    background: -webkit-linear-gradient(top, #f7f7f7 0%,#f7f7f7 39%,#ace0ef 77%,#70d3f4 100%);
                    background: -o-linear-gradient(top, #f7f7f7 0%,#f7f7f7 39%,#ace0ef 77%,#70d3f4 100%);
                    background: -ms-linear-gradient(top, #f7f7f7 0%,#f7f7f7 39%,#ace0ef 77%,#70d3f4 100%);
                    background: linear-gradient(to bottom, #f7f7f7 0%,#f7f7f7 39%,#ace0ef 77%,#70d3f4 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#70d3f4',GradientType=0 );
                }
            }
        }

        tbody {
            td {
                padding: 5px 2px;
                font-size: 12px;
            }
        }
    }
}

.table-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .data-table {
        margin: 0;
    }
}

.data-table-loading {
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 20;

    .progress-container {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 400px;
        margin-left: -200px;
        display: none;
    }

    .progress {
        margin-bottom: 5px;
    }

    .progress-bar {
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -ms-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
    }

    .background {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: #333;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
        filter: alpha(opacity=60);
        -moz-opacity: 0.6;
        -khtml-opacity: 0.6;
        opacity: 0.6;
    }

    .loading-indicator {
        position: fixed;
        width: 160px;
        top: 50%;
        left: 50%;
        height: 40px;
        margin-top: -20px;
        margin-left: -80px;
        display: block;
    }
}

.data-actions {
    white-space: nowrap;
 
    button {
        cursor: pointer;
    }

    &.bulk-actions {
        border-left: 1px solid #efefef;
        padding-left: 10px;
        margin: 10px;

        .pagination:empty + & {
            border-left: none;
        }
    }

    > .btn {
        .fa-stack {
            width: 1em;
            height: 1em;
            line-height: 1em;
        }

        .fa-stack-2x {
            font-size: 2em;
            width: 1em;
            margin-left: -0.25em;
            margin-top: -0.25em;
        }
    }
}

.data-table-controls {
    min-height: 74px;
    padding: 10px 0;

    .pagination, .filter-dropdown, .right-buttons, .left-buttons {
        margin: 10px 0;
    }

    .filter-dropdown {
        position: absolute;
        left: 0;
    }

    .left-buttons {
        position: absolute;
        left: 0;

        .filter-dropdown {
            position: relative;
            margin: 0;
        }

        button, a {
            margin-right: 10px;
        }
    }

    .right-buttons {
        position: absolute;
        right: 0;

        button {
            margin-left: 10px;
        }
    }

    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.data-table-loading .loading-indicator {
    .col-sm-6 & {
        position: absolute;
        top: 160px;
        margin-left: -80px;
    }

    .col-sm-8 & {
        margin-left: 100px;
    }
}

.full-grid-container {
    .data-table {
        margin-top: 74px;
    }

    .data-table-controls {
        position: fixed;
        top: 80.67px;
        z-index: 10;
        left: 25px;
        right: 25px;

        padding-left: 0; padding-right: 0;

        .banner-visible & {
            top: 122px;
        }

        &:before {
            display: block; content: "";
            position: absolute; top: 0; bottom: 0;
            right: -100%; left: -100%;
            background-image: url(../../../images/gui/bg/wall.png);
        }
    }

    .data-table-loading {
        left: 0;
        right: 0;
    }
}

.data-table-linker-comparison-container {
    .data-table-comparison-left {
        .table-wrapper {
            padding-left: 30px;
        }
    }

    .data-table-comparison-right {
        .table-wrapper {
            padding-right: 30px;
        }
    }
}