﻿@import "../../lib/elements.less";
@import "../../lib/font-awesome-variables.less";

.rs-form {
    min-height: 0;

    &.dropdown-menu {
        position: absolute;
        padding: 0;
        background-color: #333;
        background-color: rgba(0,0,0,0.8);
        min-width: 350px;

        & > .panel-body {
            max-height: 450px;
            overflow: auto;
        }
    }
    
    .form-group {
        z-index: 9;
    }

    .input-group-btn {
        position: static;
    }

    .btn {
        margin-left: 5px;
    }

    .control-label {
        small {
            position: absolute;
            right: 0;
            line-height: 14px;
            margin-top: 4px;
            color: #aaa;
        }
    }
}

.rs-form-search {
    &.selected {
        input {
            color: #428bca;
            text-decoration: underline;
        }
    }
}

.filter-list {
    display: inline;
    margin-left: 10px;
    font-size: 1.2em;
}