﻿@import "../../lib/elements.less";
@import "../../lib/font-awesome-variables.less";

ul.selection-list {
    list-style-type: none;
    padding-left: 0;

    li {
        padding: 5px 0 5px 40px;
        cursor: pointer;
        color: #ddd;
        background-color: #444;

        &.selected {
            color: #fff;
            background-color: #777;
            position: relative;

            &:before {
                content: @fa-var-check;
                font-family: FontAwesome;
                position: absolute;
                left: 0; top: 50%; margin-top: -11px;

                width: 40px; height: 22px;
                text-align: center;
            }
        }

        &:hover {
            background-color: #666;
        }

        &.loading {

        }
    }

    &.ui-sortable {
        li {
            &:after {
                content: @fa-var-bars;
                font-family: FontAwesome;
                margin-right: 20px;
                float: right;
            }
        }
    }
}