﻿@import "../../lib/elements.less";

.filter-panel-body {
    .field-group-header {
        &.active {
            border-left: 0; border-top: 0; border-bottom: 0;
            border-right: 5px solid #428bca;

            -moz-border-image: -moz-linear-gradient(top, #428bca, #3071a9);
            -o-border-image: -o-linear-gradient(top, #428bca, #3071a9);
            -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
            -webkit-border-image: -webkit-linear-gradient(top, #428bca, #3071a9);
            border-image: linear-gradient(to bottom, #428bca, #3071a9);

            border-image-slice: 1;
        }
    }

    h5 {
        margin-bottom: 0;
    }

    .form-control, .form-control-static {
        padding: 10px 0;
    }

    input.form-control {
        padding: 10px;
    }

    .rs-dropdown {
        width: 100%;
    }

    .rs-dropdown > button {
        width: 100%;
    }

    .dropdown-menu {
        width: 100%;
    }

    .btn {
        margin-left: 0;
    }

    label {
        small {
            position: absolute;
            right: 15px;
            line-height: 14px;
            margin-top: 4px;
            color: #aaa;
        }
    }

    .panel {
        .form-group:last-child {
            margin-bottom: 0;
        }
    }
}

.filter-form {
    .panel {
        padding: 15px 10px;
    }

    .filter-clear {
        float: right;
        font-size: 10px;
        line-height: 8px;
        margin: 3px 0;
    }
}

